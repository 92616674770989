<template>
  <div class="position-relative mt-5 profile-swiper">
    <div v-if="loadCat"></div>
    <template v-else>
      <iq-card class="p-3" v-if="allCategory">
        <h3 class="text-primary font-weight-bold mb-3">
          {{ $t("main.categories") }}
        </h3>
        <div v-if="allCategory.length > 5" class="arrows arrow-category">
          <span class="swiper-button-prev top-50 position-absolute left-20">
            <div
              class="icon-category d-flex justify-content-center align-items-center"
            >
              <i class="las la-angle-left"></i>
            </div>
          </span>
        </div>
        <b-row>
          <b-col md="2" class="d-none d-lg-block" cols="12">
            <div
              :class="[
                { 'active-categories': selectCategory === 0 },
                'iq-border-radius-20  shadow-categories d-flex flex-column justify-content-center align-items-center margin-7 py-4 ml-3 padding-all-categories',
              ]"
              @click="
                selectCategory = 0;
                $emit('changeCat', '');
              "
            >
              <div class="d-flex flex-column align-items-center">
                <svg
                  class="mt-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 50 50"
                >
                  <g
                    id="_5a6c7159824611aa6f5204b0696eb48a"
                    data-name="5a6c7159824611aa6f5204b0696eb48a"
                    transform="translate(0.75 0.75)"
                  >
                    <path
                      id="Path_1725"
                      data-name="Path 1725"
                      d="M6.689-.75h7.594a7.473,7.473,0,0,1,7.439,7.489V14.4a7.473,7.473,0,0,1-7.439,7.489H6.689A7.473,7.473,0,0,1-.75,14.4V6.739A7.473,7.473,0,0,1,6.689-.75ZM14.284,18.4a3.98,3.98,0,0,0,3.951-4V6.739a3.98,3.98,0,0,0-3.951-4H6.689a3.98,3.98,0,0,0-3.951,4V14.4a3.98,3.98,0,0,0,3.951,4ZM34.216-.75h7.6A7.471,7.471,0,0,1,49.25,6.739V14.4a7.471,7.471,0,0,1-7.437,7.489h-7.6A7.473,7.473,0,0,1,26.777,14.4V6.739A7.473,7.473,0,0,1,34.216-.75Zm7.6,19.15a3.979,3.979,0,0,0,3.949-4V6.739a3.979,3.979,0,0,0-3.949-4h-7.6a3.98,3.98,0,0,0-3.951,4V14.4a3.98,3.98,0,0,0,3.951,4ZM6.689,26.612h7.594A7.474,7.474,0,0,1,21.723,34.1v7.658a7.473,7.473,0,0,1-7.439,7.489H6.689A7.473,7.473,0,0,1-.75,41.761V34.1A7.474,7.474,0,0,1,6.689,26.612Zm7.594,19.15a3.98,3.98,0,0,0,3.951-4V34.1a3.982,3.982,0,0,0-3.951-4H6.689a3.982,3.982,0,0,0-3.951,4v7.658a3.98,3.98,0,0,0,3.951,4Zm19.933-19.15h7.6A7.473,7.473,0,0,1,49.25,34.1v7.658a7.471,7.471,0,0,1-7.437,7.489h-7.6a7.473,7.473,0,0,1-7.439-7.489V34.1A7.474,7.474,0,0,1,34.216,26.612Zm7.6,19.15a3.979,3.979,0,0,0,3.949-4V34.1a3.98,3.98,0,0,0-3.949-4h-7.6a3.982,3.982,0,0,0-3.951,4v7.658a3.98,3.98,0,0,0,3.951,4Z"
                      transform="translate(0 0)"
                      fill="#3f6854"
                    />
                  </g>
                </svg>
                <p class="text-center text-primary font-size-20 p-0 m-0 mt-4">
                  {{ $t("marketplace.all") }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="10" cols="12">
            <div>
              <Swiper
                :id="`post-slider-list-of-stores`"
                :options="swiperOptions"
                :pagination="false"
              >
                <swiperSlide
                  v-for="collection in allCategory"
                  :key="collection.id"
                >
                  <div class="w-100 py-2">
                    <section
                      :class="[
                        {
                          'active-categories':
                            selectCategory === collection.id + 1,
                        },
                        'iq-border-radius-20 w-100 shadow-categories d-flex flex-column justify-content-center align-items-center p-3 cursor-pointer',
                      ]"
                      @click="
                        selectCategory = collection.id + 1;
                        $emit('changeCat', collection.id);
                      "
                    >
                      <div
                        class="category-image-background"
                        :style="{
                          'background-image': 'url(' + collection.image + ')',
                        }"
                      ></div>
                      <p
                        class="text-center text-primary font-size-20 p-0 m-0 mt-2"
                      >
                        {{
                          collection.name.length >= 15
                            ? collection.name.slice(0, 15) + "..."
                            : collection.name
                        }}
                      </p>
                    </section>
                  </div>
                </swiperSlide>
              </Swiper>
            </div>
          </b-col>
        </b-row>
      </iq-card>
    </template>
  </div>
</template>
<script>
/*eslint-disable*/
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
export default {
  data() {
    return {
      allCategory: [],
      selectCategory: 0,
      swiperOptions: {
        centeredSlides: false,
        autoplay: false,
        pagination: {
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            centeredSlides: true,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false,
          },
          992: {
            slidesPerView: 4.5,
            spaceBetween: 30,
            centeredSlides: false,
          },
        },
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      loadCat: true,
    };
  },
  methods: {
    getAllCategoryStore() {
      this.loadCat = true;
      marketPlace
        .getCategoryStore(this.$route.params.slogan)
        .then((res) => {
          this.allCategory = res.data.data;
        })
        .finally(() => {
          this.loadCat = false;
        });
    },
  },
  created() {
    this.getAllCategoryStore();
  },
};
</script>
<style>
.swiper-button-prev {
  left: 17px !important;
}
.profile-swiper .swiper-container-post-slider-list-of-stores {
  padding-right: 20px !important;
}
</style>
