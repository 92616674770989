<template>
  <b-container>
    <iq-card class="d-flex iq-border-radius-20 overflow-hidden">
      <router-link :to="{name:'productDetails', params:{productId: productItem.id}}">
      <div class="product-main-image" :style="{ 'background-image': 'url(' +  productItem.featured_image + ')' }">
        <div class="d-flex justify-content-end p-2" v-if="productItem.flash_sales.length > 0">
          <span class="discount-product">{{productItem.flash_sales ? productItem.flash_sales[productItem.flash_sales.length - 1].percentage : ''}}%</span>
        </div>
      </div>
      </router-link>
      <div class="d-flex flex-column justify-content-start flex-grow-1 pr-3">
        <router-link :to="{name:'productDetails', params:{productId: productItem.id}}">
          <p class="text-primary font-size-30 pt-4 pb-2">{{ productItem.name}}</p>
          <p class="prod-info text-muted">{{productItem.description.length >= 120 ? productItem.description.slice(0, 120)+'....'  : productItem.description}}</p>
        <div class="star-icons d-flex">
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
          <img
              class="inline-flex mr-1"
              :src="require('@/assets/images/ibbil/star.svg')"
          />
        </div>
        </router-link>
      </div>
      <div class="d-flex flex-column justify-content-between">
        <div class="text-end">
        <b-button
            v-if="!checkFavourite(productItem)"
            @click.prevent="addToFavourite(productItem)"
            class="heart-button">
          <i class="las la-heart"></i>
        </b-button>
          <b-button
              v-else
              @click.prevent="deleteProductFavourite(productItem)"
              class="heart-favourite-button">
            <i class="las la-heart"></i>
          </b-button>
        </div>
        <div class="d-flex align-items-end gap_2 product-price-info">
          <div class="d-flex align-items-center gap_2" v-if="productItem.flash_sales.length > 0">
            <p class="price-offer m-0 font-size-38">{{productItem.price}}</p>
            <p class="last-price text-primary m-0 font-size-45 font-weight-bold">{{productItem.price_info.final_price}}<span class="text-primary font-size-25 font-weight-bold pr-1">ر.س</span></p>
          </div>
          <div class="d-flex align-items-center gap_2" v-else>
            <p class="last-price text-primary m-0 font-size-45 font-weight-bold">{{productItem.price_info.final_price}}<span class="text-primary font-size-25 font-weight-bold pr-1">ر.س</span></p>
          </div>
          <b-button
              v-if="!check(productItem)"
              @click.prevent="addToCart(productItem)"
              variant="primary"
              class="d-flex justify-content-between bg-transparent border-0 border-black text-black font-bold product-cart-item"
          >
            <span>{{ $t('marketplace.addToCart') }}</span>
            <i class="las la-shopping-cart ml-0 mr-2 font-size-18"></i>
          </b-button>
          <b-button
              v-else
              variant="outline-primary"
              class="d-flex justify-content-between font-bold product-card-button-list"
          >
            <span>{{ $t('marketplace.inCart') }}</span>
            <i class="las la-shopping-cart ml-0 mr-2 font-size-18"></i>
          </b-button>
        </div>
      </div>
    </iq-card>
  </b-container>
</template>

<script>
// import { core } from '@/config/pluginInit'
import productFunctions from '@/mixins/productFunctions'
export default {
  name: 'profileProductsList',
  props: {
    inFav: {
      default: false
    },
    productItem: {
      dafault: {}
    }
  },
  mixins: [productFunctions],
  data () {
    return {
      defaultImage: require('@/assets/images/ibbil/ibbil-cover.jpg'),
      title: 'جلد طبيعى جلد خروف',
      description: 'عبارة عن وصف التصنيع',
      price: '550',
      offer: '120.54',
      unit: 'ر.س',
      rating: 5,
      discount: 16
    }
  },
  computed: {
    totalPrice () {
      return this.productItem.tax ? this.productItem.price * this.productItem.tax.percentage / 100 : this.productItem.price
    },
    totalOfferPrice () {
      return this.productItem.tax ? this.productItem.flash_sales[this.productItem.flash_sales.length - 1].price * this.productItem.tax.percentage / 100 : this.productItem.flash_sales[this.productItem.flash_sales.length - 1].price
    }
  }
  // mounted () {
  //   core.index()
  // }
}
</script>

<style>
.product-card-button-list {
  border-radius: 0px 10px 0px 19px !important;
}
.product-main-image{
  width: 250px;
  height: 250px;
  background-size: cover;
  background-position: center;
}
.product-main-image img{
  width: 100%;
  height: 100%;
}
.prod-info{
  width: 300px;
}
.price-offer{
  line-height: 1;
}
.last-price{
  line-height: 1;
}
.font-size-45{
  font-size: 45px !important;
}
.font-size-25{
  font-size: 25px !important;
}
.text-end{
  text-align: end !important;
}
.heart-button {
  border: none !important;
  background-color: #EDEDED !important;
  border-radius: 0 0 18px 0 !important;
  padding: 8px 20px !important;
}
.heart-button i{
  color: #FFFFFF;
  font-size: 35px;
  margin-left: 0px !important;
}
.heart-favourite-button {
  border: none !important;
  background-color: #EDEDED !important;
  border-radius: 0 0 18px 0 !important;
  padding: 8px 20px !important;
}
.heart-favourite-button i {
  color: #D39D45;
  font-size: 35px;
  margin-left: 0px !important;
}
.product-cart-item{
  border-radius: 0px 10px 0 20px !important;
  padding: 13px 23px !important;
}
.font-size-30{
  font-size: 30px !important;
}
</style>
